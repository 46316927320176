<template>
<div>roles {{$t('hello')}}</div>
</template>

<script>
export default {
name: "roles"
}
</script>

<style scoped>

</style>